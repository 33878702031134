<template>
  <div>
    <div
      class="text-left block"
      :class="
        isBig ? 'font-sans text-sm md:text-base font-medium' : 'list-label'
      "
    >
      <span v-if="label" class="ml-4">
        {{ showStarIcon ? `${$t(label)}*` : $t(label) }}
      </span>
      <div
        class="flex flex-row items-center border focus-within:outline-none focus-within:ring"
        :class="{
          'border-red': validity !== 'valid',
          'rounded-md': isMm,
          'rounded-full': !isMm,
          'mt-2': isBig,
          'mt-1': !isBig,
        }"
      >
        <input
          name="password"
          :value="modelValue"
          class="placeholder-opacity-50 article-label appearance-none text-black leading-tight !font-medium px-4 rounded-full flex-grow"
          :class="{
            'rounded-md': isMm,
            'rounded-full': !isMm,
            'py-4': isBig,
            'py-2': !isBig,
          }"
          v-bind="$attrs"
          :type="type"
          required
          :pattern="pattern ? pattern : undefined"
          :minlength="minlength ? minlength : undefined"
          :maxlength="maxlength"
          :placeholder="$t(placeholder)"
          @input="handleInput"
          @blur="validateInput"
        />
        <button
          v-if="allowToggle"
          type="button"
          class="appearance-none rounded-full"
          @click="toggleVisibility"
        >
          <EyeSlashIcon
            v-if="type === 'password'"
            class="w-5 my-1 text-blue mx-2"
          />
          <EyeIcon v-else class="w-5 text-blue my-1 mx-2" />
        </button>
      </div>
    </div>
    <template v-if="validity !== 'valid'">
      <p
        v-if="validity === 'patternMismatch'"
        tag="p"
        class="list-label text-red text-right mt-1"
      >
        {{ $t('passwordRequirements') }}
      </p>
      <i18n-t
        v-else
        :keypath="'formErrors.' + validity"
        tag="p"
        scope="global"
        class="list-label text-red text-right mt-1"
      >
        <template #field>
          {{ $t('Password') }}
        </template>
        <template #fieldLowerCase>
          {{ $t('Password').toLowerCase() }}
        </template>
        <template #minlength>
          {{ minlength }}
        </template>
        <template #maxlength>
          {{ maxlength }}
        </template>
      </i18n-t>
    </template>
  </div>
</template>

<script setup lang="ts">
import EyeSlashIcon from '~/assets/icons/eye-slash-solid.svg?component'
import EyeIcon from '~/assets/icons/eye-solid.svg?component'
import type { Validity } from './Input.vue'
const { isMm } = useBrand()

withDefaults(
  defineProps<{
    modelValue: string
    allowToggle?: boolean
    placeholder?: string
    minlength?: number | false
    maxlength?: number
    pattern?: string | false
    label?: string
    isBig?: boolean
    showStarIcon?: boolean
  }>(),
  {
    allowToggle: false,
    showStarIcon: false,
    placeholder: '',
    minlength: 8,
    pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).+',
  }
)

const type = ref<'password' | 'text'>('password')

function toggleVisibility() {
  if (type.value === 'password') {
    type.value = 'text'
  } else {
    type.value = 'password'
  }
}

const validity = ref<Validity>('valid')

function validateInput(event: Event) {
  const target = event.target as HTMLInputElement
  const validityArray = Object.keys(
    Object.getPrototypeOf(target.validity)
  ) as Validity[]
  validity.value = validityArray.filter((key) => target?.validity[key])[0]
}

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value)
}
</script>
